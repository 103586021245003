
import { defineComponent, PropType } from 'vue';
import Icon from '@/components/icon/Icon';
import IconComponent from '@/components/icon/IconComponent.vue';
import { Student } from '@/models';

export default defineComponent({
  components: {
    IconComponent
  },
  props: {
    student: {
      type: Object as PropType<Student>
    }
  },
  setup() {
    return {
      Icon
    };
  }
});
