
import { defineComponent, PropType } from 'vue';
import TrendingComponent from '@/components/TrendingComponent.vue';
import { Student } from '@/models';

export default defineComponent({
  components: {
    TrendingComponent
  },
  props: {
    student: {
      type: Object as PropType<Student>
    }
  }
});
