import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-4" }
const _hoisted_2 = { class: "border-r" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_selector = _resolveComponent("date-selector")
  const _component_student_selector = _resolveComponent("student-selector")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("section", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_date_selector, { class: "p-6 py-4 border-b bg-primary-dark" }),
        _createVNode(_component_student_selector, {
          students: _ctx.students,
          activeStudent: _ctx.activeStudent,
          onSelect: _ctx.handleSelectedStudent
        }, null, 8, ["students", "activeStudent", "onSelect"])
      ]),
      _createVNode(_component_router_view, {
        class: "col-span-3",
        key: _ctx.$route.fullPath
      })
    ])
  ]))
}