
import { computed, defineComponent, PropType } from 'vue';
import StudentSelector from '@/components/student/StudentSelectorComponent.vue';
import DateSelector from '@/components/DateSelectorComponent.vue';
import { Student } from '@/models';
import Routes from '@/router/Routes';
import { useRoute, useRouter } from 'vue-router';
import store from '@/store';

export default defineComponent({
  components: {
    DateSelector,
    StudentSelector
  },
  props: {
    students: {
      type: Array as PropType<Array<Student>>
    }
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const activeStudent = computed(() => {
      return props.students?.find(
        (student) => String(student.id) === route.params.id
      );
    });

    async function handleSelectedStudent(student: Student) {
      // Deselect if they click on the same student
      if (student === activeStudent.value) {
        router.push({
          name: Routes.PLAN_EVENT_COLLECTION
        });
      } else {
        // Fetch student data before routing. This is needed since the beforeEnter does not fire when its currently on the same route
        await store.dispatch('studentModule/fetch', student.id);
        router.push({
          name: Routes.PLAN_STUDENT_DETAILS,
          params: { id: String(student.id) }
        });
      }
    }

    return { activeStudent, handleSelectedStudent };
  }
});
