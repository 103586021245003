
import StudentCard from '@/components/student/StudentCardComponent.vue';
import { Student } from '@/models';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    StudentCard
  },
  props: {
    students: {
      type: Array as PropType<Array<Student>>
    },
    activeStudent: {
      type: Object as PropType<Student>
    }
  },
  setup() {
    return {};
  }
});
