
import { defineComponent, ref } from 'vue';
import IconComponent from './icon/IconComponent.vue';
import Icon from './icon/Icon';

export default defineComponent({
  components: { IconComponent },
  setup() {
    const date = ref(new Date());
    return {
      Icon,
      date
    };
  }
});
