import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "flex",
  "data-testid": "test"
}
const _hoisted_2 = { class: "pl-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_component = _resolveComponent("icon-component")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.student.trendingDirection === 'up')
      ? (_openBlock(), _createBlock(_component_icon_component, {
          key: 0,
          icon: _ctx.Icon.TREND_UP,
          "data-testid": "trend-up"
        }, null, 8, ["icon"]))
      : (_ctx.student.trendingDirection === 'down')
        ? (_openBlock(), _createBlock(_component_icon_component, {
            key: 1,
            icon: _ctx.Icon.TREND_DOWN,
            "data-testid": "trend-down"
          }, null, 8, ["icon"]))
        : (_openBlock(), _createBlock(_component_icon_component, {
            key: 2,
            icon: _ctx.Icon.TREND_NONE,
            "data-testid": "trend-none"
          }, null, 8, ["icon"])),
    _createVNode("div", _hoisted_2, _toDisplayString(_ctx.student.trendingPercent) + "%", 1)
  ]))
}